// @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply text-gray-900;
  
    font-family: "Titillium Web", sans-serif;
    // @apply text-xs;

    @apply bg-[#ffffff];
  }
  

  :root {
    --side-nav-width: clamp(35px, 3vw , 50px); /* Default value */
  }